var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-12"},[_c('indexHeader',{attrs:{"title":_vm.$t('products.products'),"description":_vm.$t('products.fromHereYouCanControlYourProducts'),"showExport":'true',"exportLink":_vm.path + '/export/excel',"showBarcode":'true',"barcodePath":'productsBarcode',"goldBarcodePath":'goldBarcode',"showImport":'true',"explanationPath":_vm.path + '/explaination'}}),_c('indexTable',{attrs:{"searchText":_vm.$t('products.searchForAPproduct'),"emptyTableText":_vm.$t('products.thereAreNoProducts'),"buttonRole":_vm.$user.admin || _vm.$user.role.products_add,"localData":true,"emptyTableSubText":_vm.$t('allerts.TryAddingSomeItemsToTheTableAndTryAgain'),"cloumns":[
                             {column: 'name',    title:_vm.$t('products.ProductName') ,type: 'mainLink' , sort: true },
                             {column: 'code',  title:_vm.$t('products.ProductCode')  ,type: 'text'     , sort: true, icon: 'file-code' },
                             {column: 'section_id', title:_vm.$t('products.category')      ,type: 'link', to:'section', sort: true, link: false, icon: 'folders'},
                             {column: 'price',    title:_vm.$t('products.price')      ,type: 'text'     , sort: true, icon: 'usd-square' },
                             {column: 'cost',    title:_vm.$t('products.cost')      ,type: 'text'     , sort: true, icon: 'dollar-sign' },
                             {column: 'options', title:_vm.$t('products.settings'), type: 'options', options: [
                                 {name: 'show'},
                                 {name: 'edit',   role: _vm.$user.admin || _vm.$user.role.customers_edit},
                                 {name: 'delete', role: _vm.$user.admin || _vm.$user.role.customers_delete} ]}
                           ],"deleteText":{
                            attention: _vm.$t('allerts.Attention'),
                            areYouReallySureToDeleteTheItem: _vm.$t('allerts.areYouReallySureToDeleteTheProduct'),
                            close: _vm.$t('allerts.close'),
                            confirm: _vm.$t('allerts.confirm'),
                            loading: _vm.$t('allerts.loading'),
                           }}})],1)]),(_vm.importShow)?_c('importForm'):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }